@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Poppins:wght@700;900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth
  }

  body {
    @apply flex flex-col h-screen m-0 text-secondary;
  }

}

@layer components {

  .center {
    @apply flex justify-center items-center;
  }
  

  .markdown h1 {
    @apply text-2xl font-bold mb-10
  }
  .markdown h2 {
    @apply text-xl font-bold mb-5
  }
  .markdown p {
    @apply mb-5
  }
  .markdown ul {
    @apply list-disc pl-10 mb-5
  }
  .markdown ol {
    @apply list-decimal pl-10
  }
  .markdown li {
    @apply mb-2
  }
  .markdown img {
    @apply border-2 border-secondary rounded-xl overflow-hidden my-5
  }

}

html {
  font-size: 14px;
  font-weight: 500;
  background-color: #FFFFFF;
}

